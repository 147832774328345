import services from "../axios/dbManag";

export const editPrivacyRequest = (
  id_persona,
  privacy_1,
  privacy_2,
  privacy_3,
  privacy_4,
  privacy_5,
  info
) => {
  return services.dbManag
    .post("/anagrafiche/persone/privacy/edit", {
      id_persona,
      privacy_1,
      privacy_2,
      privacy_3,
      privacy_4,
      privacy_5,
      info,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
